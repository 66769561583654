import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as uiActions from '../../actions/ui'

import './style.css'

import config from './../../meta/config'

const breakpoint = {
  mobile: 727,
  ipadV: 768,
  ipadH: 1024,
  nav: 1087,
  desktop: 1200,
  large: 1400,
}

class Hero extends React.Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount () {

  }

  componentWillUnmount () {

  }

  // set the window width in the store
  screenWidth () {
    this.props.getScreenWidth()
  }

  render () {
    var screenWidth = this.props.screenWidth
    var _kenburns = this.props.screenWidth <= breakpoint.mobile ? '' : 'kenburns-bottom'
    var _focus = this.props.screenWidth <= breakpoint.mobile ? '' : 'focus-in-contract-bck'
    return (

      <section className='hero is-primary is-bold is-medium'>
        <div className={_kenburns + ' hero-body ' + this.props.bg_hero}>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className={'title ' + _focus}>
                    {this.props.title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Hero.propTypes = {

  screenWidth: PropTypes.number.isRequired,

}

const ConnectedHero = connect(
  state => ({
    screenWidth: state.app.screenWidth,
    screenHeight: state.app.screenHeight,
  })

)(Hero)

export default ConnectedHero
