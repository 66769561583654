import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import SoloImage from '../SoloImage'
import SoloImageHero from '../SoloImage/SoloImageHero'
import ConnectedHero from '../ConnectedHero'
const WhatWeHaveDonePageTemplate = ({title, content, contentComponent, bg_hero, grid }) => {
  const PageContent = contentComponent || Content
  const arr = grid.edges
  return (
    <div>
    <section id="hero_img">
    <SoloImageHero imgName={bg_hero} title={title} />
    </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <PageContent className='content' content={content} />
              {/*  <FormattedMessage id="aboutCopy" /> */}

            </div>
          </div>

        </div>
      </section>

      <section>
      <div className='container'>
      <div className='logoOuter columns is-multiline is-mobile'>
      {arr.map( ({node}, index) => (
      /* console.log(node.childImageSharp.fluid), */
        <div key={'id-'+ index} className='column is-one-fifth'>
      <Img className="logoImg" sizes={node.childImageSharp.fluid} alt="logo"/>
      </div>
    ))}
    </div>
    </div>
      </section>

    </div>
  )
}

WhatWeHaveDonePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
}

export default WhatWeHaveDonePageTemplate
