import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'
import WhatWeHaveDoneTemplate from '../components/WhatWeHaveDoneTemplate'

const WhatWeHaveDonePage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <div>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <WhatWeHaveDoneTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        bg_hero={post.frontmatter.bg_hero}
        solo_image={post.frontmatter.solo_image}
        solo_image_1={post.frontmatter.solo_image_1}
        solo_image_2={post.frontmatter.solo_image_2}
        grid={data.gridPicts1}
      />
    </div>
  )
}

WhatWeHaveDonePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default WhatWeHaveDonePage

export const aboutPageQuery = graphql`
  query WhatWeHaveDonePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        meta_description
        bg_hero
        slug
        solo_image
        solo_image_1
        solo_image_2
      }
    }
    gridPicts1: allFile(
      filter: { relativeDirectory: { regex: "/logos-grid/" } },
      sort: { order: ASC, fields: [name]}
    ) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxWidth: 170) {
          ...GatsbyImageSharpFluid
        }
          }
        }
      }
    }

  }
`
